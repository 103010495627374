export const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
};

export const getDeities = async () => {
    const apiPath = 'https://places-nepal-290be81f7552.herokuapp.com/api'

    const url = apiPath + '/places/deities';
    try {
        const response = await fetch(url, requestOptions('GET'))
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}