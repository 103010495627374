import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ktmDurbarSquarePicture from '../../assets/homepagepicture.jpg';
import swoyambhu from '../../assets/swoyambhu.jpg';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import HeaderSection from './PageComponents/Header.jsx';
import ArticleCard from '../../components/stories/ArticleCard';
import { useArticle } from '../../utils/articles.js';

const Home = () => {

    const articleContext = useArticle();

    const [recentArticles, setRecentArticles] = useState([]);

    useEffect(() => {
        if (recentArticles.length !== 0) return;
        setRecentArticles(articleContext.getRecentFiveArticles());
    }, [articleContext]);

    return (
        <>
            <Paper sx={{
                color: '#fff',
                textAlign: 'center',
                borderRadius: '0%',
                pr: 0,
                pl: 0,
                pt:0
            }}>
                <HeaderSection />
            </Paper>
            <Paper
                sx={{
                    color: '#000',
                    textAlign: 'center',
                    borderRadius: '0%',
                    pr: 5,
                    pl: 5,
                    pt: 0,
                    mb: 5
                }}
            >
                <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, fontSize: '2rem', mb: 2, textAlign: 'left' }}>Recent articles</Typography>

                {recentArticles.map((article) => {
                    return <ArticleCard data={ article }/>
                })}
            </Paper>
            <Paper sx={{ pt: 5, pb: 5, mt: 5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5} key="nepal-bucket-list-heading">
                        <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, fontSize: '2rem' }}>Have you added Nepal to bucketlist yet ?</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} key="nepal-bucket-list-text">
                        <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, m: 4 }}>Whether you are planning to visit Nepal or already in Nepal, use our website to plan your visit to Nepal. We provide information on hidden gems of Nepal.</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ pt: 5, pr: 0, pl: 0, color: '#fff' }}>
                <Grid container spacing={2} sx={{ color: 'white', minHeight: '60vh', backgroundImage: `linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.9)), url(${ktmDurbarSquarePicture})`, backgroundSize: 'cover' }}>
                    <Grid item xs={12} md={5} key="nepal-summary-heading">
                        <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, m: 4, fontSize: '2rem' }}>Did you know these facts about Nepal ?</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} key="nepal-summary-text" sx={{ color: '#ffffff' }}>
                        <List>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info'/></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Lumbini is the birthplace of Gautam Buddha" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="8 out of 10 highest peaks in the world are in Nepal" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal's flag is the only triangular national flag in the world" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color='info'/></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal ranks 95th in size and 49th in population" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color='info'/></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="There are 123 spoken languages and 125 distinct ethnic groups within Nepal" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color='info'/></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal has the highest lake, the deepest lake, the highest valley, the deepest gorge ..." />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ pb: 5, pr: 5, pl: 0 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5} key="nepal-summary-second-heading">
                        <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, m: 4, fontSize: '2rem' }}>What about these ? Did you know ?</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} key="nepal-summary-second-text">
                        <List>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal is a hub for adventure sports including white water rafting, mountaineering, bungee jumping and more" />
                            </ListItem>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal has monuments, scriptures, carvings as old as from 400 A.D." />
                            </ListItem>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="Nepal is full of culture and history with a living goddess and temples everywhere" />
                            </ListItem>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="There are mountains as beautiful as Switzerland" />
                            </ListItem>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="There are natural landscapes and waterfalls and hills as beautiful as New Zealand" />
                            </ListItem>
                            <ListItem alignItems='center'>
                                <ListItemIcon><PlayArrowIcon color='info' /></ListItemIcon>
                                <ListItemText sx={{ wordSpacing: '10px', letterSpacing: 1 }} primary="There are numerous culture specific cuisines as tasty as Italian and French" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ pb: 5, pr: 0, pl: 0 }}>
                <Grid container spacing={4} sx={{ pr: 5, color: 'white', minHeight: '60vh', backgroundImage: `linear-gradient(rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.9)), url(${swoyambhu})`, backgroundSize: 'cover' }}>
                    <Grid item xs={12} md={7} key="nepal-summary-second-heading">
                        <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, m: 4 }}>You can only feel Nepal</Typography>
                    </Grid>
                    <Grid item xs={12} md={5} key="nepal-introduction" sx={{ color: '#ffffff'}}>
                        <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, ml: 4 }}>
                            Nepal cannot be explained. You can only feel Nepal. With almost 125 distinct ethnic groups, it is impossible to explain what Nepal's culture is like. With the geographical divisions of plain Terai, hilly region and the Himalayas, it is difficult to explain what Nepal looks like. From the serene scenes of the mountains to the bustling streets of Kathmandu, from the rough terrains of Mustang to the jungles of Chitwan and Shukla Phanta, from the 12 laned roads of Kathmandu to days of hikes, it is impossible to explain what Nepal looks like.
                        </Typography>
                        <br/>
                        <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, ml: 4, mb:4 }}>
                            From hundreds of monuments that dates back to 4th century to the modern architecture, you are sure to be in awe. It would be unfair if I try to bring out a Nepalese dish or even 5 or 10 as there are more than hundred distinct dishes liked by and consumed by all the different ethnic groups. From adrenaline rushes with adventure sports to spiritual connection, there are plenty to do in Nepal. There are hundreds of other reasons that would make you feel entirely different when you are in Nepal. For that reason, Nepal cannot be explained, but can only be felt.
                        </Typography>
                        <br />
                        <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, ml: 4, mb: 4 }}>
                            Here, we aim to help you explore Nepal by diving deeper into the cuisines, cultures, landmarks, places, just about anything and everything that would help you to enjoy your journey whenever you are in Nepal. If you are already in Nepal, awesome! Enjoy your trip!
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ pt: 5, pl: 0, pr: 5, pb: 5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5} key="explore-with-us-heading">
                        <Typography variant='h2' sx={{ wordSpacing: '10px', letterSpacing: 1, ml: 4 }}>Excited to explore ?</Typography>
                        <br />
                        <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, ml: 4 }}>Why don't you explore Nepal's riches with us ?</Typography>
                    </Grid>
                    <Grid item xs={12} md={7} key="explore-with-us-text" sx={{ textAlign: 'center' }}>
{/*                         <Link to="/browse/places/map"><Button variant="contained" size="large" sx={{ fontWeight: 600, mr: 2, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(23, 38, 92, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Find in map</Button></Link> */}
{/*                         <Link to="/browse/places"><Button variant="contained" size="large" sx={{ fontWeight: 600, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(3, 111, 17, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Browse places</Button></Link> */}
                            <Link to="/browse/stories"><Button variant="contained" size="large" sx={{ fontWeight: 600, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(3, 111, 17, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Browse stories</Button></Link>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Home